<template>
    <v-model-table :data-function="getOrderStatusReport" :columns="columns" :show-toolbar="true">
        <template v-slot:column_sherpa_user_id="{ row }">
            <v-link :to="{ name: 'users.show', params: { user: row.sherpa_user_id } }">
                {{ (row.sherpa_user_id) }}
            </v-link>
        </template>

        <template v-slot:column_order_id="{ row }">
            <v-link :to="{ name: 'orders.show', params: { order: row.order_id } }">
                {{ (row.order_id) }}
            </v-link>
        </template>
    </v-model-table>
</template>

<script>
import ReportService from "@/services/modules/report-service";

export default {
    data() {
        return {
            getOrderStatusReport: (payload = {}) => ReportService.getOrderStatusReport(payload),
            columns: {
                sherpa_user_id: {
                    label: "Sherpa ID",
                    fullWidth: false,
                },
                sherpa_user_name: {
                    label: "Sherpa",
                    fullWidth: true,
                },
                order_id: {
                    label: "Order ID",
                    fullWidth: false,
                },
                order_line_created_at: {
                    label: "Job Created At",
                    fullWidth: false,
                },
                product_id: {
                    label: "Product ID",
                    fullWidth: false,
                },
                product_name: {
                    label: "Product",
                    fullWidth: false,
                    truncate: true,
                },
                available_to_assigned_hours: {
                    label: "Available to Assigned",
                    fullWidth: false,
                    align: "right",
                },
                assigned_to_completed_hours: {
                    label: "Assigned to Completed",
                    fullWidth: false,
                    align: "right",
                },
            },
        };
    },
    created: () => {
        this.$emit("download-link-updated", `${process.env.VUE_APP_API_URL}/downloads/order-status`);
    },
};
</script>
